import React, { useState, useRef, useEffect, useContext } from "react";
import "./ImageLayout.scss";
import { Rnd } from "react-rnd";
import Tooltip from "@mui/material/Tooltip";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FileContext from "../../context/FileContext";

import NewSlideRightArrowIcon from "../../Images/new-slide-right-icon.svg";
import NewSlideLeftArrowIcon from "../../Images/new-slide-left-icon.svg";

import Button from "@mui/material/Button";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import ImageLayoutEditor from "./ImageLayoutEditor";
// import PDFImage from "../../Images/PDFImage.jpg";
import { ToastContainer, toast } from "react-toastify";

import {
  drawDivImage,
  mousedragImage,
  // overLap,
  // closeSelec,
  // pasteMultiSelectedComps,
  // deleteMultiSelectedComps,
} from "./DrawLayoutImage";
import {
  getDynamicLayout,
  getFileDetails,
  imageIntermediateSave,
  intermediateSave,
} from "../../services/api";
import { CircularProgress } from "@mui/material";
// import { PDFToImageConverted } from "./Constants";
import CustomTabPanel from "./CustomTabPanel";
import {
  APPLICATION_TYPE_EDETAIL,
  EDETAIL_WIDTH,
  EMAILER_WIDTH,
  IMAGE_LOAIDING_ERROR,
  PDFToImageConverted,
} from "./Constants";
import { STEPPER_STEP_ADD_METADATA } from "../Root/constants";
const fullFilePath =
  process.env.REACT_APP_API_ENDPOINT + "/middleware/downloadAttachments/";

const TEMPIMAGES = [
  "design-to-code/cd59ec31-c4b9-4ab7-ab8c-13e0d623d447/input/image/images/ASC-21921-Simple_1 (4).pdf_0.jpg",
  "design-to-code/9f882a57-a8ae-4daf-8f6c-e95591b1d7c8/input/image/images/MBI-40997_PC-US-136149.pdf_0.png",
  "design-to-code/4fb6d417-d62b-4c6f-abd3-89a8130ae105/input/image/images/MBI-41704_PC-US-136369.pdf_0.jpg",
];
function ImageLayout(props) {
  const selectionDivImage = {
    width: 1,
    height: 1,
    left: 0,
    top: 0,
    pointerX: 0,
    pointerY: 0,
    timestamp: 0,
  };
  let showSelectionImage = false;
  let XstartImage;
  let YstartImage;
  let blocktypes = {
    LAYOUT_TEXT: "Paragraph",
    LAYOUT_PARAGRAPH_NOBG: "Paragraph Without Background",
    YOLO_LINES: "Border",
    YOLO_CTA: "CTA",
    LAYOUT_FIGURE: "Image",
    LAYOUT_SECTION_HEADER: "Section Header",
  };

  const [multiSelectImage, setMultiSelectImage] = useState(selectionDivImage);
  const [multiSelectedCompsImage, setMultiSelectedCompsImage] = useState([]);
  const [drawLayoutImage, setDrawLayoutImage] = useState(true);
  const [selectedDivImage, setSelectedDivImage] = useState(-1);
  const [dragOrResizeImage, setDragOrReizeImage] = useState(false);
  // const [props.arrayOfBoxesDrawnImage, props.setarrayOfBoxesDrawnImage] = useState([]);
  const [numberOfLayouts, setNumberOfLayouts] = useState(0);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuVisible, setMenuVisible] = useState(false);
  const [isHTMLLoading, setHTMLLoading] = useState(false);
  const [showMenuOrigin, setShowMenuOrigin] = useState("");
  const [currentTab, setCurrentTab] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [isCurrentTabLoading, setIsCurrentTabLoading] = useState(false);
  const fileContext = useContext(FileContext);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const arrayOfBoxesDrawnImageRef = useRef(props.arrayOfBoxesDrawnImage);
  const [selectedCropBox, setSelectedCropBox] = useState(-1);
  const [locallySavedCroppedBoxes, setLocallySavedCroppedBoxes] = useState([]);
  const [cropHeight, setCropHeight] = useState({ height: "", width: "" });
  const [locallysavedImageLayouts, setLocallysavedImageLayouts] = useState([]);
  const [showCroppingBoxes, setShowCroppingBoxes] = useState(true);
  let componentWidth = "";
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    // fileContext.selectFile(props.fileDetails);//backButton issue on Modify html page was not redirecting to image if this line was not commented
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  useEffect(() => {
    arrayOfBoxesDrawnImageRef.current = props.arrayOfBoxesDrawnImage;
    setNumberOfLayouts(props.arrayOfBoxesDrawnImage?.length);
  }, [props.arrayOfBoxesDrawnImage]);

  useEffect(() => {
    window.localStorage.removeItem("gjsProject");
    if (true) {
      setTotalPages(3);
    }
  }, []);

  if (props.fileDetails?.application_type === APPLICATION_TYPE_EDETAIL) {
    componentWidth = EDETAIL_WIDTH;
  } else {
    componentWidth = EMAILER_WIDTH;
  }

  useEffect(() => {
    if (!props.imageDimensions.modifiedWidth) {
      // setHTMLLoading(true);
      return;
    }
    // setHTMLLoading(false);

    if (props.arrayOfBoxesDrawnImage?.length) {
      // setLocallySavedCroppedBoxes(props.croppingBoxes);
      // props.setCroppingBoxes([]);
      setShowCroppingBoxes(false);
    }
    let tempArray = [];
    if (props.croppingBoxes[0] === 1) {
      for (let x in props.imageFileDetails?.file_conversion_pages[0]
        .cropped_image_data) {
        props.imageFileDetails.file_conversion_pages[0].cropped_image_data[
          x
        ].index = x;
        tempArray.push(
          props.imageFileDetails?.file_conversion_pages[0].cropped_image_data[x]
        );
      }
    }
    let newTempArray = tempArray.length ? tempArray : props.croppingBoxes;
    if (newTempArray[0]?.width <= componentWidth) {
      props.setCroppingBoxes(newTempArray);
      return;
    }
    if (!props.croppingBoxes.length || props.croppingBoxes[0] === 1) {
      let resizedAry = newTempArray.map((box) => {
        let obj = {
          left: 0,
          top: box.height_start * props.scaleFactorImage.toScaleDown,
          height:
            box.height_end * props.scaleFactorImage.toScaleDown -
            box.height_start * props.scaleFactorImage.toScaleDown,
          width: box.width * props.scaleFactorImage.toScaleDown,
          bottom: box.height_end * props.scaleFactorImage.toScaleDown,

          index: box.index,
        };
        return obj;
      });
      props.setCroppingBoxes(resizedAry);
      // setShowCroppingBoxes(true);
    }
    // setLocallySavedCroppedBoxes(resizedAry);
  }, [props.imageDimensions]);

  useEffect(() => {
    if (showCroppingBoxes) props.setarrayOfBoxesDrawnImage([]);
  }, [props.croppingBoxes]);
  const getSavedDetails = async () => {
    try {
      if (props.fileDetails) {
        setHTMLLoading(true);
        const parentId =
          props.fileDetails?.file_conversion_pages[0].conversion_id;
        const pageId = props.fileDetails?.file_conversion_pages[0].id;
        let response = {};
        getFileDetails(pageId, parentId)
          .then((res) => {
            response = res;
          })
          .catch((err) => {
            console.log("error");
          });

        const fileDetails = response?.data || props.fileDetails;
        let newArray = [];
        if (
          fileDetails?.file_conversion_pages[0].user_drawn_layouts?.[0]?.hasOwnProperty(
            "x_1"
          )
        ) {
          fileDetails?.file_conversion_pages[0].user_drawn_layouts.forEach(
            (element) =>
              newArray.push({
                left: element.x_1 * element.scaleFactorToScaleDown + "px",
                top: element.y_1 * element.scaleFactorToScaleDown + "px",
                right: element.x_2 * element.scaleFactorToScaleDown + "px",
                bottom: element.y_2 * element.scaleFactorToScaleDown + "px",
                width: element.width,
                height: element.height,
                position: "absolute",
                blocktype: element.blocktype,
                border: "1px dashed #00A5D6",
                zIndex: element.zIndex,
              })
          );
        } else {
          newArray = fileDetails?.file_conversion_pages[0].user_drawn_layouts;
        }
        props.setarrayOfBoxesDrawnImage(newArray);

        // let tempArray = [];
        // for (let x in props.fileDetails.cropped_image_data) {
        //   props.fileDetails.cropped_image_data[x].index = x;
        //   tempArray.push(props.fileDetails.cropped_image_data[x]);
        // }
        // ;
        // props.setCroppingBoxes(tempArray);
        setHTMLLoading(false);
      }
    } catch (error) {
      props.setIsGJSLoading(false);
      // console.log("Error in one of the Axios calls:", error);
      setHTMLLoading(false);
    }
  };

  useEffect(() => {
    if (
      props.fileDetails?.file_conversion_pages[0].message?.innerStatus
        ?.statusCode !== PDFToImageConverted
    ) {
      getSavedDetails();
    }
    setTotalPages(props?.fileDetails?.num_pages);
  }, [props.fileDetails]);

  const updateResizedDivsImage = (object) => {
    let temparrayOfBoxesDrawnImage = JSON.parse(
      JSON.stringify(props.arrayOfBoxesDrawnImage)
    );
    let obj =
      object.origin === "resizeStop"
        ? {
            height: object.height + "px",
            width: object.width + "px",
            left: object.left + "px",
            top: object.top + "px",
            right: object.width + object.left + "px",
            bottom: object.top + object.height + "px",
          }
        : { left: object.x + "px", top: object.y + "px" };
    temparrayOfBoxesDrawnImage[object.index] = {
      ...temparrayOfBoxesDrawnImage[object.index],
      ...obj,
    };
    // if (!temparrayOfBoxesDrawnImage[object.index].hasOwnProperty("blocktype"))
    // showMenu(obj.left, obj.top);
    for (let i = 0; i < temparrayOfBoxesDrawnImage?.length; i++) {
      if (
        i !== object.index &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].top) <
          parseFloat(temparrayOfBoxesDrawnImage[i].top) &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].left) <
          parseFloat(temparrayOfBoxesDrawnImage[i].left) &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].width) >
          parseFloat(temparrayOfBoxesDrawnImage[i].width) &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].height) >
          parseFloat(temparrayOfBoxesDrawnImage[i].height)
      ) {
        let newIndex = temparrayOfBoxesDrawnImage[object.index]?.zIndex;
        temparrayOfBoxesDrawnImage[object.index].zIndex =
          temparrayOfBoxesDrawnImage[i].zIndex;
        temparrayOfBoxesDrawnImage[i].zIndex = newIndex;
      }
    }
    props.setarrayOfBoxesDrawnImage(temparrayOfBoxesDrawnImage);
    arrayOfBoxesDrawnImageRef.current = temparrayOfBoxesDrawnImage;
  };
  const updateResizedCropDivs = (object) => {
    let temparrayOfBoxesDrawnImage = JSON.parse(
      JSON.stringify(props.croppingBoxes)
    );
    let obj =
      object.origin === "resizeStop"
        ? {
            height: object.height,
            top: object.top,
            bottom: object.top + object.height,
          }
        : { left: componentWidth + "px", top: object.y };
    temparrayOfBoxesDrawnImage[object.index] = {
      ...temparrayOfBoxesDrawnImage[object.index],
      ...obj,
    };

    for (let i = 0; i < temparrayOfBoxesDrawnImage?.length; i++) {
      if (
        i !== object.index &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].y1) <
          parseFloat(temparrayOfBoxesDrawnImage[i].y1) &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].x1) <
          parseFloat(temparrayOfBoxesDrawnImage[i].x1) &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].width) >
          parseFloat(temparrayOfBoxesDrawnImage[i].width) &&
        parseFloat(temparrayOfBoxesDrawnImage[object.index].height) >
          parseFloat(temparrayOfBoxesDrawnImage[i].height)
      ) {
        let newIndex = temparrayOfBoxesDrawnImage[object.index]?.zIndex;
        temparrayOfBoxesDrawnImage[object.index].zIndex =
          temparrayOfBoxesDrawnImage[i].zIndex;
        temparrayOfBoxesDrawnImage[i].zIndex = newIndex;
      }
    }
    //important
    props.setCroppingBoxes(temparrayOfBoxesDrawnImage);
  };
  const addCroppingBox = () => {
    if (!showCroppingBoxes) return;
    setSelectedCropBox(-1);
    let boxes = props.croppingBoxes;
    boxes.sort((a, b) => parseFloat(a.top) - parseFloat(b.top));
    let imageHeight = parseFloat(props.imageDimensions.modifiedHeight);

    let newBox =
      boxes.length === 0
        ? {
            height: imageHeight < 2000 ? imageHeight : 2000,
            bottom: imageHeight < 2000 ? imageHeight : 2000,
            top: 0,
            width: componentWidth,
            left: 0,
            index: "cropped_image_0",
          }
        : {
            bottom: parseFloat(boxes[boxes.length - 1].bottom) + 51,
            height: 51,
            index: "cropped_image_" + boxes.length,
            left: 0,
            top: parseFloat(boxes[boxes.length - 1].bottom) + 1,
            width: componentWidth,
          };

    for (let i = 0; i < boxes.length; i++) {
      let box = boxes[i];

      //when there is no box at top=0 to 10

      if (i === 0 && parseFloat(box.top) > 10) {
        newBox.top = 0;
        newBox.bottom = parseFloat(boxes[i].top) - 1;
        newBox.height = parseFloat(newBox.top) + parseFloat(newBox.bottom);
        break;
      }
      //when there is no box in the middle

      if (
        parseFloat(box.top) > 10 &&
        parseFloat(box.top) > parseFloat(boxes[i - 1].bottom) + 10
      ) {
        newBox.top = parseFloat(boxes[i - 1].bottom) + 1;
        newBox.bottom =
          parseFloat(newBox.top) +
          (parseFloat(boxes[i].top) - parseFloat(newBox.top));
        newBox.height = parseFloat(newBox.bottom) - parseFloat(newBox.top);
        break;
      }
      if (
        i === boxes.length - 1 &&
        parseFloat(boxes[i].bottom) < imageHeight - 10
      ) {
        newBox.top = parseFloat(boxes[boxes.length - 1].bottom + 1);

        if (imageHeight - parseFloat(newBox.top) > 2000) {
          newBox.height = 2000;
          newBox.bottom = parseFloat(newBox.top) + 2000;
        } else {
          newBox.bottom =
            parseFloat(newBox.top) + (imageHeight - parseFloat(newBox.top));
          newBox.height = parseFloat(newBox.bottom) - parseFloat(newBox.top);
        }

        break;
      } else if (i === boxes.length - 1) {
        toast.error("Not enough space to add the box!", {
          toastId: "no-space-error",
          pauseOnFocusLoss: false,
        });
        return;
      }
    }

    boxes = [...boxes, newBox];
    boxes.sort((a, b) => parseFloat(a.top) - parseFloat(b.top));
    props.setCroppingBoxes(boxes);
  };
  const deleteSelectedDiv = (e) => {
    // var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    if (
      (e?.key !== "Delete" && e !== "clickButton") ||
      (selectedDivImage === -1 && selectedCropBox === -1)
    )
      return;
    e?.preventDefault?.();
    e?.stopPropagation?.();
    hideMenu("delete");
    if (selectedCropBox === -1) {
      let tempArrayOfBoxesDrawn = props.arrayOfBoxesDrawnImage.filter(
        (item, index) => index !== selectedDivImage
      );
      setSelectedDivImage(-1);
      props.setarrayOfBoxesDrawnImage(tempArrayOfBoxesDrawn);
      arrayOfBoxesDrawnImageRef.current = tempArrayOfBoxesDrawn;
    } else {
      let tempCroppingBoxes = props.croppingBoxes.filter(
        (item, index) => index !== selectedCropBox
      );
      setSelectedCropBox(-1);
      props.setCroppingBoxes(tempCroppingBoxes);
    }
    if (menuVisible) hideMenu("delete");
  };
  const showMenu = (e, origin) => {
    if (!e) return;
    setShowMenuOrigin(origin);
    let x, y;
    if (e?.target) {
      e.preventDefault();
      if (selectedDivImage < 0) return;
      // if (parseFloat(y) > 480) y = parseFloat(y) - 480;
      x =
        parseFloat(props.arrayOfBoxesDrawnImage[selectedDivImage].right) - 130;

      if (parseFloat(x) < 0) {
        //GAT-171
        // if user draws layout at extreem left side then this context menu is not visible as it will start at negative left position.
        // So making it 0
        x = 0;
      }
      x = x + "px";
      y = props.arrayOfBoxesDrawnImage[selectedDivImage].top;
    } else {
      x = e?.x + "px";
      y = e?.y + "px";
      if (parseFloat(x) < 0) {
        //GAT-171
        // if user draws layout at extreem left side then this context menu is not visible as it will start at negative left position.
        // So making it 0
        x = 0;
      }
    }
    setMenuPosition({ top: parseFloat(y), left: parseFloat(x) });
    setMenuVisible(true);
  };
  const hideMenu = (origin) => {
    if (showMenuOrigin === "onStopDraw" && origin !== "delete") return;
    setMenuVisible(false);
  };
  const addType = (type) => {
    setMenuVisible(false);
    let x = selectedDivImage;
    let tempArray = JSON.parse(JSON.stringify(props.arrayOfBoxesDrawnImage));
    let newIndex =
      selectedDivImage >= 0 ? selectedDivImage : tempArray.length - 1;
    tempArray[newIndex].blocktype = type;
    props.setarrayOfBoxesDrawnImage(tempArray);
  };

  const getFiileById = (id, parentId) => {
    props.setImageFileDetails(null);
    getFileDetails(id, parentId)
      .then((res) => {
        setHTMLLoading(false);
        // fileContext.selectFile(res.data);
        props.setImageFileDetails(res.data);
        props.setIsPublished(
          res.data.file_conversion_pages[0].message.step ===
            STEPPER_STEP_ADD_METADATA
        );
        // if (showCroppingBoxes) {
        //   props.setCroppingBoxes([]);
        // } else {
        props.setarrayOfBoxesDrawnImage([]);
        arrayOfBoxesDrawnImageRef.current = [];
        setShowCroppingBoxes(true);
        // }
        if (menuVisible) hideMenu("delete");
      })
      .catch((err) => {
        setHTMLLoading(false);
      });
  };

  const getNewPage = (pageId) => {
    setHTMLLoading(true);

    if (props.arrayOfBoxesDrawnImage.length) {
      const body = {
        bbox_json: props.arrayOfBoxesDrawnImage,
        image_file_path:
          props.fileDetails?.file_conversion_pages[0]?.image_path,
        generatedUUID: props.fileDetails?.file_conversion_pages[0].page_uuid,
        file_name: props.fileDetails?.pdf_file_path?.split("/").pop(),
        id: props.imageFileDetails?.file_conversion_pages[0].id,
        parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
        cropped_image_data: Object.values(props.modifiedCroppedImage).length
          ? props.modifiedCroppedImage
          : props.ObjectOfObjectsToArrayOfObjects(
              props.fileDetails?.file_conversion_pages[0].cropped_image_data,
              {}
            ),
      };

      imageIntermediateSave(body)
        .then((res) => {
          const id = pageId;
          const parentId =
            props.fileDetails?.file_conversion_pages[0]?.conversion_id;
          getFiileById(id, parentId);
        })
        .catch((err) => {
          setHTMLLoading(false);
        });
    } else {
      const id = pageId;
      const parentId =
        props.fileDetails?.file_conversion_pages[0]?.conversion_id;
      getFiileById(id, parentId);
    }
  };

  const goNextTab = () => {
    if (props.fileDetails?.nextId) {
      getNewPage(props.fileDetails?.nextId);
    }
  };

  const goPrevTab = () => {
    if (props.fileDetails?.previousId) {
      getNewPage(props.fileDetails?.previousId);
    }
  };

  const formArrayOfContainers = (
    array,
    i,
    color,
    temparrayOfDimenions,
    ctaBoxes,
    reducedImageHeight,
    reducedImageWidth,
    litsContainer
  ) => {
    let obj = {};

    let newLeft = "";
    let newRight = "";
    let newTop = "";
    let newBottom = "";
    let scaleFactor = 1;
    if (reducedImageWidth) {
      scaleFactor = reducedImageWidth / props.imageDimensions.originalWidth;
      props.setScaleFactorImage({
        ...props.scaleFactorImage,
        toScaleDown: scaleFactor,
      });
      // if (!props.imageDimensions.modifiedWidth) {
      //   props.setImageDimensions({
      //     ...props.imageDimensions,
      //     modifiedWidth: reducedImageWidth,
      //     modifiedHeight: reducedImageHeight,
      //   });
      // }
      newLeft = array[i].x_1 * scaleFactor;
      newRight = array[i].x_2 * scaleFactor;
      newTop = array[i].y_1 * scaleFactor;
      newBottom = array[i].y_2 * scaleFactor;
    } else {
      newLeft = array[i].x_1;
      newRight = array[i].x_2;
      newTop = array[i].y_1;
      newBottom = array[i].y_2;
    }
    obj.left = color === "#00A5D6" ? newLeft - 0 : newLeft;
    let right = color === "#00A5D6" ? newRight + 0 : newRight;
    obj.top = color === "#00A5D6" ? newTop - 0 : newTop;
    // obj.left = newLeft;
    // let right = newRight;
    // obj.top = newTop;
    let bottom = color === "#00A5D6" ? newBottom + 0 : newBottom;
    if (array[i].blocktype === "LAYOUT_LIST") {
      obj.left = obj.left - 2;
      right = right + 2;
      obj.top = obj.top - 2;
      bottom = bottom + 2;

      litsContainer.push(obj);
    }

    if (ctaBoxes?.length) {
      if (
        ctaBoxes.filter(
          (ctaBox) =>
            newLeft >= parseFloat(ctaBox.x_1) * scaleFactor &&
            newTop >= parseFloat(ctaBox.y_1) * scaleFactor &&
            newRight <= parseFloat(ctaBox.x_2) * scaleFactor &&
            newBottom <= parseFloat(ctaBox.y_2) * scaleFactor
        ).length
      )
        return;
    }
    obj.right = right + "px";
    obj.bottom = bottom + "px";
    obj.width = right - obj.left + "px";
    obj.height = bottom - obj.top + "px";
    obj.left = obj.left + "px";
    obj.top = obj.top + "px";
    obj.border = `1px dashed ${color}`;
    obj.position = "absolute";
    obj.blocktype = array[i].blocktype;
    obj.zIndex = i + 100;
    temparrayOfDimenions.push(obj);
    // obj.zoom = "27.9%";
  };

  const getImageLayout = () => {
    if (!props.croppingBoxes.length) {
      toast.error("Cannot fetch layouts without cropping boxes");
      return;
    }

    let modifiedCrppedData = props.ObjectOfObjectsToArrayOfObjects();
    // let oldObj = props.imageFileDetails.cropped_image_data;
    // let newObj = {};
    // for (let x in oldObj) {
    //   let newHeight =
    //     props.croppingBoxes[parseFloat(x.replace(/[^0-9.]/g, "")) - 1];

    //   let rhsObj = {
    //     height_end:
    //       parseFloat(newHeight.bottom) / props.scaleFactorImage.toScaleDown,
    //     height_start:
    //       parseFloat(newHeight.top) / props.scaleFactorImage.toScaleDown,
    //     width: 600,
    //   };
    //   newObj[x] = rhsObj;
    // }
    // props.setModifiedCroppedImage(obj);

    const body = {
      s3_image_url: props.fileDetails?.file_conversion_pages[0]?.image_path,
      id: props.imageFileDetails?.file_conversion_pages[0].id,
      generatedUUID: props.imageFileDetails?.file_conversion_pages[0].page_uuid,
      cropped_image_data: modifiedCrppedData,
      parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
    };
    let reducedImageWidth = "";
    let reducedImageHeight = "";
    if (props.imageDimensions.originalWidth > componentWidth) {
      let reducedImage = document.querySelector(".image-rhs");
      if (reducedImage) {
        // reducedImageWidth = 600;
        reducedImageWidth = componentWidth; // This variable has width based on application type. ie 600 or 1024
        // reducedImageWidth = true ? 1024 : 600; //add condition for edetail or email here

        reducedImageHeight = reducedImage.height;
      }
    }
    setHTMLLoading(true);
    getDynamicLayout(body)
      .then((apiResp) => {
        setShowCroppingBoxes(false);
        setHTMLLoading(false);
        let data = apiResp.data;
        // props.setCooridinatesFromAPI(data);
        // props.innerLayoutsRef.current = data;
        let temparrayOfDimenions = [];
        let containersLength = data.elements_metadata.length;
        data.elements_metadata.sort(
          (a, b) => parseFloat(a.y_1) - parseFloat(b.y_1)
        );

        let ctaContainersLength = data?.yolo_metadata?.length || 0;
        props.setModifiedCroppedImage(data.cropped_image_data);

        // let elementMetaData = data.elements_metadata.filter(
        //   (item) =>
        //     item.blocktype === "LAYOUT_FIGURE" ||
        //     item.blocktype === "LAYOUT_PARAGRAPH_BG" ||
        //     item.blocktype === "YOLO_LINE" ||
        //     item.blocktype === "YOLO_CTA"
        // );
        let ctaBoxes = data?.yolo_metadata || [];

        // for (let i = 0; i < elementMetaData.length; i++) {
        //   formArrayOfContainers(
        //     elementMetaData,
        //     i,
        //     "yellow",
        //     temparrayOfDimenions,
        //     ctaBoxes
        //   );
        // }
        let litsContainer = [];
        for (let i = 0; i < containersLength; i++) {
          formArrayOfContainers(
            data.elements_metadata,
            i,
            "#00A5D6",
            temparrayOfDimenions,
            ctaBoxes,
            reducedImageHeight,
            reducedImageWidth,
            litsContainer
          );
        }

        for (let i = 0; i < litsContainer.length; i++) {
          for (let j = 0; j < temparrayOfDimenions.length; j++)
            if (
              parseFloat(litsContainer[i].top) <
                parseFloat(temparrayOfDimenions[j].top) &&
              parseFloat(litsContainer[i].bottom) >
                parseFloat(temparrayOfDimenions[j].bottom) &&
              parseFloat(litsContainer[i].left) <
                parseFloat(temparrayOfDimenions[j].left) &&
              parseFloat(litsContainer[i].right) >
                parseFloat(temparrayOfDimenions[j].right)
            ) {
              temparrayOfDimenions[j].zIndex = litsContainer[i].zIndex + j + 1;
            }
        }
        for (let i = 0; i < ctaContainersLength; i++) {
          formArrayOfContainers(
            data.yolo_metadata,
            i,
            "#ac0bf1",
            temparrayOfDimenions,
            "",
            reducedImageHeight,
            reducedImageWidth
          );
        }

        props.setarrayOfBoxesDrawnImage(temparrayOfDimenions);
      })
      .catch((err) => {
        setHTMLLoading(false);
        setShowCroppingBoxes(true);
      });
  };

  function tabStyleChange(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  if (isHTMLLoading)
    return (
      <div className="progress-bar">
        <CircularProgress />
      </div>
    );

  return (
    <div
      className="parentContainer"
      // onClick={(e) => {
      //   //  console.log("onclickevent");
      // }}
    >
      <div className="lhs">
        <ul>
          {/* <li
            className="lhsItems"
            onClick={() => {
              // hideMenu();
              setDrawLayoutImage(true);
              setSelectedDivImage(-1);
            }}
          >
            Draw Layout
          </li>*/}
          <li className="lhsItems">
            <ul
              style={{ display: "flex" }}
              className="parent-navigation middle-pannel-total-slides"
            >
              <li style={{ width: "24px" }}>
                <img
                  src={NewSlideLeftArrowIcon}
                  className={
                    !props.fileDetails?.previousId
                      ? "disableNavigate"
                      : "new-slide-left-icon"
                  }
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={goPrevTab}
                />
              </li>
              <div className="pageCount">
                <span className="page-text slideTotalCount">
                  {props.fileDetails?.pageNumber}
                </span>
                <span className="page-text"> / </span>
                <span className="page-text slideTotalCount">{totalPages}</span>
              </div>
              <li style={{ width: "22px", marginRight: "7px" }}>
                <img
                  src={NewSlideRightArrowIcon}
                  className={
                    !props.fileDetails?.nextId
                      ? "disableNavigate"
                      : "new-slide-right-icon"
                  }
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={goNextTab}
                />
              </li>
            </ul>
            {/* <div className="customPanel">
              <IconButton disabled={currentTab == 1} onClick={goPrevTab}><NavigateBeforeIcon fontSize="small" /></IconButton>
             
              <div className="pageCount">
                {JSON.stringify(currentTab) === "{}" ? (
                  <>
                    <input
                      autofocus
                      id="jumpToSlide"
                      className={"jupmToPage"}
                      type="text"
                      min="1"
                      value={currentTab}
                      onChange={goPrevTab}
                      onKeyUp={goPrevTab}
                      onBlur={goPrevTab}
                    ></input>

                    <span className="page-text"> / </span>
                    <span className="slide-text slideTotalCount">
                      {" "}
                      {3}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <input
                      autofocus
                      id="jumpToSlide"
                      className={"jupmToPage"}
                      type="text"
                      min="1"
                      value={currentTab}
                      onChange={goPrevTab}
                      onKeyUp={goPrevTab}
                      onBlur={goPrevTab}
                    ></input>

                    <span className="page-text"> / </span>
                    <span className="page-text slideTotalCount">
                      {" "}
                      {3}{" "}
                    </span>
                  </>
                )}
            </div>
              <IconButton disabled={currentTab == 3} onClick={goNextTab}><NavigateNextSharpIcon fontSize="small" /></IconButton>
            </div> */}
          </li>
          <li
            className="lhsItems"
            onClick={() => {
              hideMenu();
              setSelectedCropBox(-1);
              if (props.arrayOfBoxesDrawnImage?.length) {
                setShowCroppingBoxes(false);
              } else getImageLayout();
            }}
          >
            {/* Get Layouts */}

            <Button
              variant="outlined"
              endIcon={
                <GetAppIcon
                  sx={{ color: "black" }}
                  style={{ fontSize: "15px" }}
                />
              }
            ></Button>
            <span className="action-btn">Get Layouts</span>
          </li>
          {/*<li
            className="lhsItems"
            onClick={() => {
              //console.log("clicked dragOrResize sidebar ");
              hideMenu();
              setDragOrReizeImage(true);
              setDrawLayoutImage(false);
            }}
          >
            Select Layout
          </li>*/}
          <li
            className="lhsItems"
            onClick={() => {
              hideMenu();
              deleteSelectedDiv("clickButton");
            }}
          >
            {/* Delete */}
            <Button
              variant="outlined"
              endIcon={
                <DeleteIcon
                  sx={{ color: "black" }}
                  style={{ fontSize: "15px" }}
                />
              }
            ></Button>
            <span className="action-btn">Delete</span>
          </li>
          <li
            className="lhsItems"
            onClick={() => {
              if (showCroppingBoxes) {
                props.setCroppingBoxes([]);
              } else {
                props.setarrayOfBoxesDrawnImage([]);
                arrayOfBoxesDrawnImageRef.current = [];
                setShowCroppingBoxes(true);
              }
              if (menuVisible) hideMenu("delete");
              // props.setCroppingBoxes(locallySavedCroppedBoxes);
              // setLocallysavedImageLayouts([]);
            }}
          >
            {showCroppingBoxes ? (
              <>
                <Button
                  variant="outlined"
                  endIcon={
                    <ClearAllIcon
                      sx={{ color: "black" }}
                      style={{ fontSize: "15px" }}
                    />
                  }
                ></Button>
                <span className="action-btn">Clear all Cropping boxes</span>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  endIcon={
                    <ClearAllIcon
                      sx={{ color: "black" }}
                      style={{ fontSize: "15px" }}
                    />
                  }
                ></Button>
                <span className="action-btn">Clear all Layouts</span>
              </>
            )}
          </li>
          <li
            className="lhsItems"
            onClick={() => {
              // setLocallysavedImageLayouts(props.arrayOfBoxesDrawnImage);
              // props.setarrayOfBoxesDrawnImage([]);
              // props.setCroppingBoxes(locallySavedCroppedBoxes);
              setShowCroppingBoxes(true);
              setSelectedDivImage(-1);
            }}
          >
            <Button
              variant="outlined"
              endIcon={
                <VisibilityIcon
                  sx={{ color: "black" }}
                  style={{ fontSize: "15px" }}
                />
              }
            ></Button>
            <span className="action-btn">Show cropping Layouts</span>
          </li>
          <li
            className="lhsItems"
            onClick={() => {
              hideMenu();
              addCroppingBox();
            }}
          >
            <Button
              variant="outlined"
              endIcon={
                <AddBoxOutlinedIcon
                  sx={{ color: "black" }}
                  style={{ fontSize: "15px" }}
                />
              }
            ></Button>
            <span className="action-btn">Add Cropping Box</span>
          </li>
        </ul>
      </div>
      <div className="rhs custonScrollBar">
        <div
          id={"rhs"}
          className="custonScrollBar"
          style={{
            display: "inline - block",
            margin: "0px auto",
            userSelect: "none",
            width: componentWidth + "px",
            position: "relative",
            outline: "none",
            backgroundColor: "#f3f6f9",
          }}
          onContextMenu={(e) => {
            if (showCroppingBoxes) return;
            e.preventDefault();
            props.arrayOfBoxesDrawnImage?.length && showMenu(e, "onBoxClick");
          }}
          onKeyDown={(e) => {
            // if (showCroppingBoxes) return;

            deleteSelectedDiv(e);
          }}
          tabIndex={0}
          onMouseDown={(e) => {
            if (showCroppingBoxes || e.target.tagName === "LI") return;
            hideMenu();
            !e.button && setSelectedDivImage(-1);
            e.detail < 2 &&
              drawDivImage(
                e,
                showSelectionImage,
                XstartImage,
                YstartImage,
                setMultiSelectImage,
                multiSelectImage,
                props.arrayOfBoxesDrawnImage,
                props.setarrayOfBoxesDrawnImage,
                arrayOfBoxesDrawnImageRef,
                showMenu,
                props.scaleFactorImage
              );
          }}
          onMouseMove={(e) => {
            if (showCroppingBoxes) return;

            mousedragImage(
              e,
              showSelectionImage,
              XstartImage,
              YstartImage,
              setMultiSelectImage,
              setMultiSelectedCompsImage,
              multiSelectImage
            );
          }}
        >
          <div
            id={"drawSelectionDiv"}
            style={{
              width: multiSelectImage.width + "px",
              height: multiSelectImage.height + "px",
              left: multiSelectImage.left + "px",
              top: multiSelectImage.top + "px",
              position: "absolute",
              zIndex: 100,
              border: "1px dashed  #f177e5",
            }}
          ></div>
          <img
            src={
              fullFilePath +
              props.fileDetails?.file_conversion_pages[0].image_path
            }
            // src={fullFilePath + props.fileDetails?.[currentTab - 1].image_file_path}
            className="image-rhs"
            width={componentWidth + "px"}
            onLoad={(e) => {
              props.setIsGJSLoading(false);
              props.setImageDimensions({
                ...props.imageDimensions,
                originalWidth: e.target.naturalWidth,
                originalHeight: e.target.naturalHeight,
                modifiedWidth:
                  e.target.naturalWidth > componentWidth ? componentWidth : 0,
                modifiedHeight:
                  (e.target.naturalHeight * componentWidth) /
                  e.target.naturalWidth,
              });
              if (e.target.naturalWidth > componentWidth) {
                let scaleFactor = componentWidth / e.target.naturalWidth;

                props.setScaleFactorImage({
                  ...props.scaleFactorImage,
                  toScaleDown: scaleFactor,
                });
              }
            }}
            onError={() => {
              props.setIsGJSLoading(false);
            }}
            alt={IMAGE_LOAIDING_ERROR}
          />
          {showCroppingBoxes
            ? props.croppingBoxes?.map((cropBox, index) =>
                selectedCropBox !== index ? (
                  <div
                    className="section-border"
                    style={{
                      left: 0,
                      top: cropBox.top,
                      width: componentWidth + "px",
                      height: cropBox.height,
                      border: "1px dashed #26f2e9",
                      position: "absolute",
                      zIndex: 998,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCropBox(index);
                    }}
                  ></div>
                ) : (
                  <Rnd
                    key={index}
                    style={{
                      border: "1px dashed #3b97e3",
                      zIndex: 999,
                    }}
                    default={{
                      x: 0,
                      y: parseFloat(cropBox.top),
                      width: cropBox.width,
                      height: cropBox.height,
                    }}
                    bounds="parent"
                    resizeHandleStyles={{
                      bottomRight: {
                        backgroundColor: "blue",
                        width: "5pxpx",
                        height: "5pxpx",
                        right: "-3px",
                        bottom: "-3px",
                      },
                      bottomLeft: {
                        backgroundColor: "blue",
                        width: "5pxpx",
                        height: "5pxpx",
                        left: "-3px",
                        bottom: "-3px",
                      },
                      topRight: {
                        backgroundColor: "blue",
                        width: "5pxpx",
                        height: "5pxpx",
                        right: "-3px",
                        top: "-3px",
                      },
                      topLeft: {
                        backgroundColor: "blue",
                        width: "5pxpx",
                        height: "5pxpx",
                        left: "-3px",
                        top: "-3px",
                      },
                    }}
                    // size={{
                    //   height: cropHeight.height || cropBox.height,
                    //   width: 600,
                    // }}
                    // onResize={(e, direction, ref, delta, position) => {
                    //   ;
                    //   if (ref.offsetHeight > 1300)
                    //     setCropHeight({ height: "1300px", width: 600 });
                    //}}
                    enableResizing={{
                      top: cropHeight > 5000 ? false : true,
                      left: false,
                      right: false,
                      bottom: cropHeight > 5000 ? false : true,
                    }}
                    disableDragging={true}
                    onResizeStop={(e, direction, ref, delta, position) => {
                      updateResizedCropDivs({
                        index: index,
                        width: componentWidth,
                        height: ref.offsetHeight,
                        origin: "resizeStop",
                        left: 0,
                        top: position.y,
                      });
                    }}
                    // onDragStop={(e, direction, ref, delta, position) => {
                    //   updateResizedCropDivs({
                    //     index: index,
                    //     ...direction,
                    //     origin: "dragStop",
                    //   });
                    // }}
                  >
                    {" "}
                    <Tooltip
                      open={true}
                      title={`height:${props.croppingBoxes[index].height}`}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "1px dashed blue",
                        }}
                      ></div>
                    </Tooltip>{" "}
                  </Rnd>
                )
              )
            : props.arrayOfBoxesDrawnImage?.map((box, index) =>
                selectedDivImage !== index ? (
                  <Tooltip title={`blocktype : ${box.blocktype}`}>
                    <div
                      className="section-border"
                      name={"section" + index}
                      style={box}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDivImage(index);
                      }}
                    ></div>
                  </Tooltip>
                ) : (
                  <Rnd
                    key={index}
                    style={{
                      zIndex: box.zIndex, // Set your desired z-index value
                      /* Other styles for the Rnd component */
                    }}
                    default={{
                      x: parseFloat(box.left),
                      y: parseFloat(box.top),
                      width: box.width,
                      height: box.height,
                    }}
                    bounds="parent"
                    resizeHandleStyles={{
                      bottomRight: {
                        backgroundColor: "blue",
                        width: "5px",
                        height: "5px",
                        right: "-3px",
                        bottom: "-3px",
                      },
                      bottomLeft: {
                        backgroundColor: "blue",
                        width: "5px",
                        height: "5px",
                        left: "-3px",
                        bottom: "-3px",
                      },
                      topRight: {
                        backgroundColor: "blue",
                        width: "5px",
                        height: "5px",
                        right: "-3px",
                        top: "-3px",
                      },
                      topLeft: {
                        backgroundColor: "blue",
                        width: "5px",
                        height: "5px",
                        left: "-3px",
                        top: "-3px",
                      },
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                      updateResizedDivsImage({
                        index: index,
                        width: ref.offsetWidth,
                        height: ref.offsetHeight,
                        origin: "resizeStop",
                        left: position.x,
                        top: position.y,
                      });
                    }}
                    onDragStop={(e, direction, ref, delta, position) => {
                      updateResizedDivsImage({
                        index: index,
                        ...direction,
                        origin: "dragStop",
                      });
                    }}
                  >
                    <Tooltip title={`blocktype : ${box.blocktype}`}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "1px solid blue",
                        }}
                      ></div>
                    </Tooltip>
                  </Rnd>
                )
              )}
          {menuVisible && (
            <div
              style={{
                position: "absolute",
                top: menuPosition.top,
                left: menuPosition.left,
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1000,
              }}
              className="modify-image-contextMenu"
            >
              <ul>
                <li onClick={() => addType("LAYOUT_FIGURE")}>Image</li>
                <li onClick={() => addType("LAYOUT_FIGURE_WITH_TEXT")}>
                  Image with text
                </li>
                <li onClick={() => addType("LAYOUT_TEXT")}>Paragraph</li>
                <li onClick={() => addType("LAYOUT_PARAGRAPH_NOBG")}>
                  Paragraph Without Background
                </li>
                <li onClick={() => addType("YOLO_LINES")}>Border</li>
                <li onClick={() => addType("YOLO_CTA")}>Button</li>
                <li onClick={() => addType("LAYOUT_TABLE")}>Table</li>
                <li onClick={() => addType("LAYOUT_LIST")}>List</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageLayout;
